body {
  background-image: url("./planet@2x.png");
  background-repeat: no-repeat;
  background-position-x: right;
  display: flex;
  background-color: rgb(11, 2, 26);
}

html,
body,
.App,
#root {
  height: 100%;
  width: 100%
}

.App {
  display: flex;
  flex-direction: column;
  border-radius: 25px 25px 25px 25px;
}

.container {
  background-image: url("./fightscene@2x.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.button-container {
  margin: 0 auto;
  margin-bottom: 2.5%;
  text-align: center;
  justify-content: center;
  width: 100%;
}

.nav-container {
  height: 56px;
  display: flex;
  align-items: center;
  padding: 40px;
  padding-bottom: 10px;
}

.connect-button {
  color: white;
  background-color: rgb(125,5,140);
  height: 48px;
  border-radius: 25px;
  border: none;
  padding: 0 24px;
  font-weight: 600;
  font-size: 1.1rem;
  margin-left: auto;
}

.approve-button, .stake-button, .unstake-button {
  display: inline-flexbox;
  width: 10%;
  margin-left: 2.5%;
  margin-right: 2.5%;
  height: 55px;
  color: white;
  background-color: rgb(125,5,140);
  border: 1px;
  font-weight: 600;
  font-size: 1.1rem;
  border-radius: 25px 25px 25px 25px;
  }

.NFTStakedBox {
  margin: 0 auto;
  height: auto;
  margin-bottom: 2.5%;
  justify-content: center;
  color: aliceblue;
  border-style: solid;
  border-width: px;
  border-color: rgb(0, 160, 152);
  width: 50%;
  box-shadow: 0 16px 32px 0 rgba(255, 255, 255, 0.2);
  transition: 0.3s;
  border-radius: 5px 5px 5px 5px;
}

.connect-button:hover{
  cursor: pointer;
  background-color: rgb(195,6,183);
}

.text {
  color: white;
  text-align: left;
  margin-left: 25%;
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 16px;
}

.wallet-text {
  color: white;
  margin-top: 0%;
  margin-left: auto;
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 10%;
  padding-right: 2.5%;
}

.nft-container {
  display: grid;
  margin: 15%;
  margin-top: 0;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, 19%);
  margin-left: 19%;
}

.staked-nft-container {
  color: white;
  display: grid;
  grid-template-columns: repeat(auto-fill, 20%);
  margin-top: 0;
  justify-content: left;
  margin-left: 0%;
  padding: 2.5%;
}

.nft-container-selected {
  color: rgb(8, 238, 19);
  border: 10px solid;
  display: grid;
  grid-gap: 2.5%;
  margin: 5%;
  margin-top: 0%;
  grid-template-columns: repeat(auto-fill, 21.05%);
}

.staked-nft-card {
    border-style: solid;
    border-color: rgb(0, 160, 152);
    width: 70%;
    box-shadow: 0 16px 32px 0 rgba(255, 255, 255, 0.2);
    transition: 0.3s;
    border-radius: 5px;
    margin:15px;
}

.nft-card {
  color: white;
  margin-top: 8%;
  border-style: solid;
  border-color: rgb(0, 160, 152);
  width: 60%;
  box-shadow: 0 16px 32px 0 rgba(255, 255, 255, 0.2);
  transition: 0.3s;
  border-radius: 5px;
}

.nft-card:hover {
  background-color: rgb(0, 160, 152);
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  transform: scale(1.05);
}

.nft-image {
  border-radius: 5px 5px 0 0;
  width: 100%;
}

.footer {
  color: white;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  padding-bottom: 10px;
}